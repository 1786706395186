import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import JennieveLogo from '../assets/jennieve-logo.png';

const Header = () => {
  return (
    <Navbar expand="lg" className='navbar'>
    <Container>
      <Navbar.Brand href="./"><img src={JennieveLogo} alt='logo' className="jennieve-logo"></img></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link className='navlink-text' href="./">Home</Nav.Link>
          <Nav.Link className='navlink-text' href="./aboutus">About Us</Nav.Link>
          <Nav.Link className='navlink-text' href="./contactus">Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Header
