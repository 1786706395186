import React from 'react'

const Footer = () => {
  let today = new Date();
  let year = today.getFullYear();

  return (
    
    <div className='footer'>
    <p>TYJ Jennieve Holdings Sdn. Bhd.</p>
    <p>Copyright © {year}</p>
    </div>
  )
}

export default Footer
