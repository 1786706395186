import plusCover from '../assets/plus/cover.PNG'
import plusIngredientEN1 from '../assets/plus/ingredient-en1.JPG'
import plusIngredientEN2 from '../assets/plus/ingredient-en2.JPG'
import plusIngredientCN1 from '../assets/plus/ingredient-cn1.JPG'
import plusIngredientCN2 from '../assets/plus/ingredient-cn2.JPG'
import plus1 from '../assets/plus/1.JPG'
import plus2 from '../assets/plus/2.JPG'
import plus3 from '../assets/plus/3.JPG'
import plus4 from '../assets/plus/4.JPG'
import plus5 from '../assets/plus/5.JPG'
import plus6 from '../assets/plus/6.JPG'
import plus7 from '../assets/plus/7.JPG'
import plus8 from '../assets/plus/8.JPG'
import plus9 from '../assets/plus/9.JPG'

import mealCover from '../assets/meal/cover.PNG'
import mealIngredientEN from '../assets/meal/ingredient-en.png'
import meallevel1 from '../assets/meal/level1.png'
import meallevel2 from '../assets/meal/level2.png'
import meallevel3 from '../assets/meal/level3.png'
import meal1 from '../assets/meal/1.JPG'
import meal2 from '../assets/meal/2.JPG'
import meal3 from '../assets/meal/3.JPG'

import careCover from '../assets/care/cover.PNG'
import careIngredientCN1 from '../assets/care/ingredient-cn1.JPG'
import careIngredientCN2 from '../assets/care/ingredient-cn2.JPG'
import careIngredientEN1 from '../assets/care/ingredient-en1.JPG'
import careIngredientEN2 from '../assets/care/ingredient-en2.JPG'
import care1 from '../assets/care/1.JPG'
import care2 from '../assets/care/2.JPG'
import care3 from '../assets/care/3.JPG'
import care4 from '../assets/care/4.JPG'
import care5 from '../assets/care/5.JPG'
import care6 from '../assets/care/6.JPG'

import collCover from '../assets/coll/cover.PNG'
import collIngredientCN1 from '../assets/coll/ingredient-cn1.JPG'
import collIngredientEN1 from '../assets/coll/ingredient-en1.JPG'
import coll1 from '../assets/coll/1.JPG'
import coll2 from '../assets/coll/2.JPG'
import coll3 from '../assets/coll/3.JPG'
import coll4 from '../assets/coll/4.JPG'
import coll5 from '../assets/coll/5.JPG'
import coll6 from '../assets/coll/6.JPG'

export const plusImage = [plusCover, plus1, plus2, plus3, plus4, plus5, plus6, plus7, plus8, plus9, plusIngredientEN1, plusIngredientEN2, plusIngredientCN1, plusIngredientCN2]
export const mealImage = [mealCover, mealIngredientEN, meallevel1, meallevel2, meallevel3, meal1, meal2, meal3]
export const careImage = [careCover, care1, care2, care3, care4, care5, care6, careIngredientEN1, careIngredientEN2, careIngredientCN1, careIngredientCN2]
export const collImage = [collCover, coll1, coll2, coll3, coll4, coll5, coll6, collIngredientEN1, collIngredientCN1 ]