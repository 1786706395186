import React from "react";
import { Row } from "react-bootstrap";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import shopeeLogoImage from "../assets/shopee-logo-contact.jpg";
import lazadaLogoImage from "../assets/lazada-logo-contact.jpg";

const ContactUs = () => {
  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-title">Get In Touch</div>
        <div className="about-us-explain">
          Connect with us for support, guidance, or just a friendly chat.
          Your wellness journey is important to us, and we're just a message away.
        </div>
        <div className="about-us-top">

          <div className="about-us-card-wrapper">

            <div className="about-us-card">
              <div className="about-us-item">
                <div className="upper">

                  <div className="icon-wrapper"><MailOutlineIcon className="company-info-icon" />
                  </div><div className="text"><p>EMAIL</p></div>
                </div>
                <div className="lower">
                  <div className="words">Our friendly team is here to help.</div>

                  <a className='company-info-text email' href="mailto:jennievemalaysia@gmail.com">jennievemalaysia@gmail.com</a>
                </div>
              </div>
            </div>

            <div className="about-us-card">
              <div className="about-us-item">
                <div className="upper">

                  <div className="icon-wrapper"><LocationOnIcon className="company-info-icon" />
                  </div><div className="text"><p>OFFICE</p></div>
                </div>
                <div className="lower">
                  <div className="words">Come say hello at our office HQ.</div>

                  <p className='company-info-text'>
                    TYJ Jennieve Holdings Sdn. Bhd.<br />201701032640 (1246810-K)
                  </p>

                  <p className='company-info-text'>
                    No 15, Lorong Perkasa Jaya, Taman Perkasa Jaya, 13000 Butterworth,
                    Pulau Pinang, Malaysia.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-us-card about-us-card-last-child">
              <div className="about-us-item">
                <div className="upper">

                  <div className="icon-wrapper"><SmartphoneIcon className="company-info-icon" />
                  </div><div className="text"><p>PHONE</p></div>
                </div>
                <div className="lower">
                  <div className="words">Mon-Fri from 8am to 5pm</div>
                  <a className='company-info-text email' href="tel:60183666289">+6018-3666289</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us-social">
          <a
            href="https://www.facebook.com/jennievemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <FacebookIcon className="social-icon" />
          </a>
          <a
            href="https://instagram.com/jennievemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <InstagramIcon
              className="social-icon"
              style={{ margin: "0px 1px" }}
            />
          </a>
          <a
            href="https://wa.link/gcrr81"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <WhatsAppIcon className="social-icon" />
          </a>
          <a
            href="https://shopee.com.my/jennievemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <img src={shopeeLogoImage} className="social-shopee-img" alt='shopee'></img>
          </a>
          <a
            href="https://www.lazada.com.my/shop/jennieve-malaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <img src={lazadaLogoImage} className="social-lazada-img" alt='lazada'></img>
          </a>
        </div>
      </Row>

    </div>
  );
};

export default ContactUs;
