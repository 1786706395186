import React from "react";
import { Row } from "react-bootstrap";
import aboutImage from "../assets/about-image.jpg";

const AboutUs = () => {

  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-title">About Us</div>
        <div className="about-us-explain">
          At Jennieve, we're passionate about nutrition. Our journey began with a commitment
          to enhancing wellness through high-quality food supplements and expert guidance.
        </div>
        <div className="about-us-top">
          <div className="about-us-card-wrapper">

            <div className="about-us-card">
              <div className="about-us-item">
                <div className=" title">
                  OUR MISSION

                </div>

                Welcome to Jennieve Health, we are dedicated to making a healthy life accessible to all through our groundbreaking products. We specialize in food and health supplements, supported by an in-house research team and extensive industry experience. Our product line includes Detox Fibre Drink, Nutrition Meal Replacement Drink, Women's Menstruation and Hormones Regulating Drink, and Marine Fish Collagen Peptide Drinks.
              </div>
            </div>

            <div className="about-us-card about-us-card-last-child">
              <div className="about-us-item">
                <div className="title">
                  我们的使命
                </div>

                欢迎来到 Jennieve Health，我们致力于通过我们的创新产品让每个人都能拥有健康的生活。我们专注于保健食品，拥有专业的内部研究团队和丰富的行业经验。我们的产品包括纤维排毒， 营养代餐，女性调经调激素饮料，和深海鱼胶原蛋白肽。
              </div>
            </div>
          </div>
        </div>

      </Row>
    </div>
  );
};

export default AboutUs;
